@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .MuseoSans100{
    font-family: 'MuseoSans-100', sans-serif;
  }

  p {
    @apply MuseoSans100
  }
}

svg {
  display: block;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background: black;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  border-top: 1.1em solid rgba(155, 154, 154, 0.2);
  border-right: 1.1em solid rgba(155, 154, 154, 0.2);
  border-bottom: 1.1em solid rgba(155, 154, 154, 0.2);
  border-left: 1.1em solid #0081C6;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .timeline-container::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: black;
    left: 7.5%;
    z-index: -1;
  }
}

@font-face {
  font-family: BebasNeueProRegular;
  src: url("/fonts/BebasNeueProRegular.otf") format("opentype");
}

@font-face {
  font-family: MuseoSans-100;
  src: url("/fonts/MuseoSans-100.otf") format("opentype");
}

@font-face {
  font-family: MuseoSans-300;
  src: url("/fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-family: MuseoSans-500;
  src: url("/fonts/MuseoSans-500.otf") format("opentype");
}

@font-face {
  font-family: ChronicleDisplayLight;
  src: url("/fonts/Chronicle\ Display\ Light.otf") format("opentype");
}

@font-face {
  font-family: ChronicleDisplayRegular;
  src: url("/fonts/Chronicle\ Display\ Regular.otf") format("opentype");
}

@font-face {
  font-family: ChronicleDisplaySemibold;
  src: url("/fonts/Chronicle\ Display\ Semibold.otf") format("opentype");
}






